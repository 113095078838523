<template>
  <v-container id="user-profile" fluid tag="section" class="text_google">
    <v-row justify="center">
      <v-col cols="12" lg="12">
        <v-bottom-navigation :background-color="color" horizontal>
          <v-btn id="btnnavi" color="primary" max-width="100%"  elevation="3" rounded to="/pages/login">
           <h2><span class="font-weight-light white--text">เข้าสู่ระบบ : ข้าราชการครูและบุคลากรทางการศึกษา</span></h2> 
            <v-icon>mdi-account-key</v-icon>
          </v-btn>

          <v-btn id="btnnavi" color="warning" max-width="100%" elevation="3" rounded to="/pages/loginAdmin">
            <h2><span  class="font-weight-light">เข้าสู่ระบบ : สถานศึกษา</span></h2>

            <v-icon>mdi-login-variant</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-col>
      <v-col cols="12" md="8">
        <base-material-card color="primary darken-2">
          <template v-slot:heading>
            <div class="font-weight-light">
              <h2>Login ข้าราชการครูและบุคลากรทางการศึกษา</h2>
            </div>

            <div class="font-weight-light">Username : รหัสบัตรประชาชน</div>
            <div class="font-weight-light">
              Password : วันเดือนปีเกิด : 8/8/2531
            </div>
          </template>

          <v-form ref="form" lazy-validation>
            <v-text-field
              prepend-icon="mdi-account"
              id="id_card"
              name="id_card"
              label="ชื่อผู้ใช้ : รหัสบัตรประชาชน"
              type="text"
              v-model="id_card"
              :rules="[v => !!v || 'กรุณากรอกชื่อผู้ใช้']"
            ></v-text-field>
            <v-text-field
              id="p_word"
              prepend-icon="mdi-lock-outline"
              name="p_word"
              label="รหัสผ่าน : 8/8/2531"
              v-model="p_word"
              :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="userLogin()" color="success" rounded class="mr-0">
              <v-icon dark small>mdi-login</v-icon>&nbsp;&nbsp; เข้าสู่ระบบ
            </v-btn>
          </v-card-actions>
        </base-material-card>

        <base-material-card color="error darken-2">
          <template v-slot:heading>
            <div class="font-weight-light">
              <h2>Login พนักงานราชการ ครูอัตราจ้าง ลูกจ้างประจำ ลูกจ้างชั่วคราว </h2>
            </div>

            <div class="font-weight-light">Username : รหัสบัตรประชาชน</div>
            <div class="font-weight-light">
              Password : รหัสบัตรประชาชน ในครั้งแรก
            </div>
          </template>

          <v-form ref="form_etc" lazy-validation>
            <v-text-field
              prepend-icon="mdi-account"
              id="id_card"
              name="id_card"
              label="ชื่อผู้ใช้ : รหัสบัตรประชาชน"
              type="text"
              v-model="personnel_etc_id_card"
              :rules="[v => !!v || 'กรุณากรอกชื่อผู้ใช้']"
            ></v-text-field>
            <v-text-field
              id="p_word"
              prepend-icon="mdi-lock-outline"
              name="p_word"
              label="รหัสผ่าน : รหัสบัตรประชาชน"
              v-model="personnel_etc_pass"
              :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="userEtcLogin()" color="success" rounded class="mr-0">
              <v-icon dark small>mdi-login</v-icon>&nbsp;&nbsp; เข้าสู่ระบบ
            </v-btn>
          </v-card-actions>
        </base-material-card>

      </v-col>

      <v-col cols="12" md="4">
        <base-material-card
          class="v-card-profile"
          avatar="ovec.png"
        >
          <v-card-text class="text-center">
            <h2 class="font-weight-light mb-1 grey--text">เข้าสู่ระบบ</h2>
            <h1 class="font-weight-light mb-3 black--text">
              ข้าราชการครูและบุคลากรทางการศึกษา
            </h1>
            <p class="font-weight-light grey--text">
              ชื่อผู้ใช้ระบบ : รหัสบัตรประชาชน
            </p>
            <p class="font-weight-light red--text">
              รหัสผ่าน : วันเดือนปีเกิด "8/8/2531" ไม่ต้องระบุ 0 นำหน้า
            </p>
            <p class="font-weight-light black--text">
              เข้าสู่ระบบแล้วให้ดำเนินการเปลี่ยนรหัสผ่านของท่าน
            </p>
            <v-btn color="success" to="/news" rounded class="mr-0">คู่มือ</v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="dialog" top>
      <v-card-text>
        {{ dialog_msg }}
      </v-card-text>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dialog = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      id_card: "",
      p_word: "",
      dialog: false,
      dialog_msg: "",
      snackbar_timeout: 10000,

      personnel_etc_id_card:'',
      personnel_etc_pass:'',
    };
  },
  mounted() {
    //Null
  },
  methods: {
    async userLogin() {
      if (this.$refs.form.validate()) {
        let result = await this.$http.post("login.php?crud=user", {
          id_card: this.id_card,
          p_word: this.p_word
        });
        if (result.data.user_status) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (user.user_status == "teacher" || user.user_status == "se_director" || user.user_status == "director" || user.user_status == "S" || user.user_status == "38" || user.user_status == "3801" || user.user_status == "3802" || user.user_status == "3803" || user.user_status == "C") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/user");
          }    
          else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        }
      }
    },

    async userEtcLogin(){
      if (this.$refs.form_etc.validate()) {
        let result = await this.$http.post("login_etc.php?crud=user", {
          personnel_etc_id_card: this.personnel_etc_id_card,
          personnel_etc_pass: this.personnel_etc_pass
        });
        
        if (result.data.personnel_etc_m_type) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (user.personnel_etc_m_type == "governmentteach") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/userEtc");
          } else if (user.personnel_etc_m_type == "governmentsp") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/userEtc");
          } else if (user.personnel_etc_m_type == "temporary") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/userEtc");
          }
          else if (user.personnel_etc_m_type == "temporary_job") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/userEtc");
          }

          else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        }
      }
    }
  },
   computed: {
      color () {
      return 'green lighten-1'      
    }
   }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");
</style>

<style>
.text_google {
  font-family: "Kanit", sans-serif;
}
#btnnavi {
  width: 50%;
}
</style>
